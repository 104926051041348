<template>
	<div class="dashboard">
		<Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Order Details</h1>
        <router-link :to="{ name: 'custOrders' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
      <div class="flex flex-wrap">
        <div class="flex__col1">
          <transition name="fadeStop">
            <Loader v-if="!order" />
          </transition>
        	<div class="card" v-if="order">
            <h2 class="mb-2">PO# {{order.order.poNumber}}</h2>
            <h4>{{order.created.toDate() | moment("MMM Do YYYY")}}<span class="light"> // Status: {{order.status}}</span></h4>
            <p v-if="order.order && order.order.details">{{order.order.details}}</p>
            <a :href="order.order.po" target="_blank">
              <button class="btn btn__primary mt-3">
                View PO <i class="ml-1 fad fa-external-link"></i>
              </button>
            </a>
          </div>
        </div>
        <div class="flex__col2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'order',
  components: {
    Loader,
    Breadcrumbs
  },
  created () {
    this.$store.dispatch("getOrderFromId", this.$route.params.id);
  },
  computed: {
    ...mapState(['currentUser', 'customerProfile', 'order']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "My Orders", to: { name: "custOrders"}}
      let step3 = { title: "Order Details", to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      crumbsArray.push(step3)
      return crumbsArray
    },
  },
  beforeDestroy(){
    this.$store.dispatch('clearOrder')
  }
}
</script>
